import React from "react"
//import { Link } from "gatsby"

import Layout from "../../components/layout"
import Typography from "../../components/typography"
import SEO from "../../components/seo"

const TypographyPage = () => (
  <Layout>
    <SEO title="Typography" />
    <Typography />
  </Layout>
)

export default TypographyPage
